import type { NotifyResponse, TokenResponse } from '~/models'
import { useMainStore, useUserStore } from '#imports'

export function notifyBy(response: NotifyResponse): void {
  const { message = {} } = response || {}
  const { display: { component = null, content = null } = {} } = message

  const mainStore = useMainStore()
  const componentBox: { [key: string]: () => void } = {
    banner: () => window.alert('fireBanner' + content),
    snackbar: () => mainStore.fireSnackbar({ message }),
    dialog: () => mainStore.setDialogAttributes({ message }),
  }

  if (component && componentBox[component]) {
    componentBox[component]()
  }
}

export function handleToken(response?: TokenResponse) {
  const userStore = useUserStore()

  // const { verify_token, email_token, forget_token } = response?.data?.payload || {}
  // verify_token && store.dispatch('auth/setVerifyPayload', response?.data?.payload)
  // email_token && store.dispatch('auth/setEmailToken', email_token)
  // forget_token && store.dispatch('auth/setForgetToken', forget_token)

  const authToken =
    response?.headers?.['x-auth-token'] ||
    response?.data?.payload?.auth_token ||
    (import.meta.client && localStorage.getItem('auth-token'))

  if (authToken) userStore.addAuthToken(authToken)
}

export const formSubmitEvent = (response: any, status: string) => {
  return { response, status }
}

export const signOut = async () => {}
export const fireMaintenanceMode = () => {}
export const fireBanner = () => {}
