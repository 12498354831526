import { defineStore } from 'pinia'
import jsonmergepatch from 'json-merge-patch'
import type {
  MFAVerify,
  PromiseAny,
  SignIn,
  SignUp,
  User,
  ResetPassword,
  ForgotPassword,
  Verification,
  CurrencyAddress,
  UserOpenOrderList,
  ObjectRecord,
  ChangePassword,
  ApiKeyCreatePayload,
  CurrencyConfig,
} from '~/models'
import Pusher from '@/plugins/pusher'

export const useUserStore = defineStore('UserStore', {
  // USER STATE
  state: (): User => {
    return {
      auth_token: null,
      showSignUpModal: null,
      favorites: [],
      pinned_market: '',
      newAddress: {},
      transactions: {},
      lastWithdraw: {},
      alarmFilters: {},
      transaction: {},
      baseCurrency: null,
      isAssetVisible: null,
      notificationSettings: {},
      announcementSettings: {},
      FinancialHistory: {},
      FinancialHistoryMeta: {},
      historyFilters: {
        processes: [],
        currencies: [],
        started_at: '',
        ended_at: '',
        period: '30',
        page: 1,
        per_page: 25,
      },
      verifyPayload: {},
      email_token: '',
      forget_token: '',
      redirectRoute: {},
      user: {},
      userSessions: [],
      apiKeys: [],
    } as User
  },
  // USER ACTIONS
  actions: {
    signIn(payload: SignIn) {
      return $http.post('auth/sign-in', payload)
    },

    addAuthToken(token: string): void {
      this.auth_token = token
    },

    fetchArticle({ article }: { article: string }): PromiseAny {
      return $fetch(`https://paribu.zendesk.com/api/v2/help_center/articles/${article}`)
    },

    signUp(payload: SignUp): PromiseAny {
      return $http.post('auth/v2/sign-up', payload)
    },

    verifyIdentification(payload: any): PromiseAny {
      return $http.post('mfa/verify/tcnum', payload)
    },

    mfaVerify(payload: MFAVerify): PromiseAny {
      return $http.post('mfa/verify?reason=sign-in', payload)
    },

    mfaResend(payload: { token: string }): PromiseAny {
      return $http.post('mfa/resend', payload)
    },

    mfaVerifyEmailSms(payload: {
      token: string
      email_code: string
      sms_code: string
    }): PromiseAny {
      return $http.post('mfa/verify/sms-email', payload)
    },

    userVerification(payload: Verification): PromiseAny {
      return $http.post('user/verification', payload)
    },

    fetchUser(): PromiseAny {
      return $http.get('user').then((res: any) => {
        const mainStore = useMainStore()

        if (res?.payload?.config) {
          deepMerge(mainStore?.config, res?.payload?.config)
        }

        this.user = res.payload

        Pusher.subscribe({
          mutation: 'mergeUser',
          store: 'userStore',
          channel: `private-user-${res?.payload.info.uid}`,
          force: { action: 'userFetcher' },
          payload: this.user,
        })

        Pusher.subscribe({
          mutation: 'mergeUser',
          store: 'userStore',
          channel: `private-notification-${res?.payload.info.uid}`,
        })
      })
    },

    mergeUser(payload: ObjectRecord): void {
      if (payload.action === 'merge') {
        jsonmergepatch.apply(this.user, payload.payload)
      }
      if (payload.action === 'replace') {
        this.user = payload.payload
      }
    },

    userFetcher(): void {
      const mainStore = useMainStore()

      $http.get('user').then((res: any) => {
        if (res?.payload?.config) $_.merge(mainStore?.config, res?.payload?.config)
        this.user = res.payload
      })
    },

    forgotPassword(payload: ForgotPassword): PromiseAny {
      return $http.post('auth/forget', payload)
    },

    resetPassword(payload: ResetPassword): PromiseAny {
      return $http.post('auth/new-password', payload)
    },

    toggleSignUpModal(value: string | null): void {
      this.showSignUpModal = value
    },

    logout(): void {
      localStorage.removeItem('auth-token')
      this.user = {}
    },

    changeUserEmail(payload: { new_email: string }): PromiseAny {
      return $http.post('user/change-email', payload)
    },

    deleteCurrencyAddress(addressUid: string): PromiseAny {
      return $http.delete(`addresses/${addressUid}`)
    },

    createCurrencyAddress(payload: CurrencyAddress): PromiseAny {
      return $http.post('addresses', payload)
    },

    fetchUserSessions(): void {
      $http.get('user/sessions').then((data: any) => (this.userSessions = data.payload))
    },

    deleteUserSession(id: string): PromiseAny {
      return $http.delete(`user/${id}`)
    },

    freezeAccount(payload: { reason: string }): PromiseAny {
      return $http.post('user/deactivate', payload)
    },

    fetchNotificationSettings(): PromiseAny {
      return $http.get('notification/settings').then((data: any) => {
        this.notificationSettings = data.payload
      })
    },

    changeNotificationSettings(payload: {
      channel: string
      setting: string
      value: boolean
    }): PromiseAny {
      return $http.post('notification/settings', payload)
    },

    fetchAnnouncementSettings(): PromiseAny {
      return $http.get('announcement/settings').then((data: any) => {
        this.announcementSettings = data.payload
      })
    },

    changeAnnouncementSettings(payload: {
      channel: string
      setting: string
      value: boolean
    }): PromiseAny {
      return $http.post('announcement/settings', payload)
    },

    changePassword(payload: ChangePassword): PromiseAny {
      return $http.post('auth/change-password', payload)
    },

    async fetchApiKeys(): PromiseAny {
      return await $http.get('/api-keys').then((data: any) => {
        this.apiKeys = data.payload
      })
    },

    createApiKey(payload: ApiKeyCreatePayload): PromiseAny {
      return $http.post('/api-keys', payload)
    },

    deleteApiKey(id: string): PromiseAny {
      return $http.delete(`/api-keys/${id}`)
    },

    updateApiKey(updatedData: ObjectRecord): PromiseAny {
      return $http.put(`/api-keys/${updatedData.id}`, updatedData)
    },

    assignDepositAddress(payload: ObjectRecord) {
      return $http.post('addresses/assign', payload)
    },

    setBaseCurrency(): void {
      this.baseCurrency = localStorage.getItem('base-currency') === 'tl' ? 'usd' : 'tl'
      localStorage.setItem('base-currency', this.baseCurrency)
    },

    setAssetVisibility(): void {
      this.isAssetVisible =
        this.isAssetVisible === null
          ? !JSON.parse(localStorage.getItem('is-asset-visible') as string)
          : !this.isAssetVisible

      localStorage.setItem('is-asset-visible', this.isAssetVisible)
    },

    fetchWithdraw(payload: ObjectRecord): PromiseAny {
      return $http.post('withdraws', payload)
    },

    getWithdraw(transactionId: string): PromiseAny {
      return $http.get(`withdraws/${transactionId}`)
    },

    cancelTransaction(transactionId: string): PromiseAny {
      return $http.delete(`withdraws/${transactionId}`)
    },

    toggleFavoriteMarket(market: string): PromiseAny {
      return $http.post('/favorite', { market })
    },

    async fetchUserPrivateChannels(payload: ObjectRecord): PromiseAny {
      const res = await $http.post('user/pusher/auth', payload)
      return { data: res }
    },

    fetchOrderDetails(id: string): PromiseAny {
      return $http.get(`orders/${id}`)
    },
  },

  // USER GETTERS
  getters: {
    getAuthToken: async () => await localStorage.getItem('auth-token'),

    isUserAuthenticated: (state: User): boolean => state.user?.info,

    getBaseCurrency: (state) => {
      if (process.client) {
        return state.baseCurrency === null
          ? (localStorage.getItem('base-currency') as string) || 'tl'
          : state.baseCurrency
      }

      return state.baseCurrency
    },

    getAssetVisible: (state) =>
      state.isAssetVisible === null
        ? JSON?.parse(localStorage?.getItem('is-asset-visible') as string)
        : state.isAssetVisible,

    getUsersAvailableAssets: (state: User): ObjectRecord => {
      const mainStore = useMainStore()
      const currencies = mainStore?.config?.currencies
      const fiatCurrencies = $_.keys(
        $_.pickBy(currencies, (currency: CurrencyConfig) => currency?.labels?.includes('fiat')),
      )
      const assets = $_.pickBy(state.user?.assets, (asset, key) => !currencies?.[key]?.hidden)
      return $_.keyBy(
        $_.chain(assets)
          .map((asset, symbol) => {
            let totalValue = 0
            if (!fiatCurrencies.includes(String(symbol))) {
              totalValue = mul({
                numA: Number(mainStore?.ticker?.[`${symbol}_${getBaseCurrency()}`]?.last),
                numB: Number(asset?.total > 0 ? asset?.total : 0),
              })
            } else {
              totalValue = asset?.total > 0 ? asset?.total : 0
            }
            return {
              ...asset,
              symbol,
              color: '#' + currencies?.[symbol]?.color,
              totalValue,
              percentage: getPercent({
                numA: Number(totalValue),
                numB: mainStore?.getUserTotalBalance,
              }),
            }
          })
          .filter((asset) => asset?.total > 0)
          .orderBy('percentage', 'desc')
          .value(),
        'symbol',
      )
    },

    getFixedAssetList: (state: User): any[] => {
      const mainStore = useMainStore()
      const resultArray: any[] = []
      for (const key in mainStore?.config?.currencies) {
        const currency = mainStore?.config?.currencies?.[key] as CurrencyConfig
        const ticker = mainStore.ticker?.[`${key}_${state.getBaseCurrency}`]
        const found = currency?.labels?.find((item) => item === 'stable')

        if (found) {
          const asset = state?.user?.assets[key]
          resultArray.push({
            key,
            fullName: currency?.name,
            icon: currency?.icon,
            last: Number(ticker?.last) || 0,
            available: Number(asset?.total),
            withdrawAvailable: Number(asset?.withdraw_available),
            percentage: Number(ticker?.change) || 0,
            tlConvertion: mul({
              numA: Number(asset?.available) || 0,
              numB: Number(ticker?.last) || 0,
            }),
            searchString: turkishToEnglish(`${currency?.name}${currency?.symbol}`.toLowerCase()),
          })
        }
      }
      return resultArray
    },

    getCryptoAssetList: (state: User): any[] => {
      const mainStore = useMainStore()
      const myCryptoList: any[] = []
      for (const key in mainStore?.config?.currencies) {
        const currency = mainStore?.config?.currencies?.[key] as CurrencyConfig
        const ticker = mainStore.ticker?.[`${key}_${state?.getBaseCurrency}`]
        const found = currency?.labels?.find((item) => item !== 'stable' && item !== 'fiat')

        if (found) {
          const asset = state?.user?.assets[key]

          const assetElement = {
            key,
            name: currency?.name,
            icon: currency?.icon,
            last: Number(ticker?.last) || 0,
            available: asset?.total,
            withdrawAvailable: Number(asset?.withdraw_available),
            percentage: Number(ticker?.percentage) || 0,
            tlConvertion: mul({
              numA: Number(asset?.available) || 0,
              numB: Number(ticker?.last) || 0,
            }),
            searchString: turkishToEnglish(`${currency?.name}${currency?.symbol}`.toLowerCase()),
          }

          myCryptoList.push(assetElement)
        }
      }
      return myCryptoList
    },

    getUser: (state) => state.user,

    getShowSignUpModal: (state) => state.showSignUpModal,

    getUserSessions: (state: User) => state?.userSessions,

    getUserOpenOrders: (state: User): UserOpenOrderList => {
      const openOrders = state?.user?.open_orders as UserOpenOrderList

      const sortedOpenOrders = $_.fromPairs(
        $_.sortBy($_.toPairs(openOrders), (pair: any) => pair[1].created_at).reverse(),
      )
      return sortedOpenOrders as UserOpenOrderList
    },
    getUserAddresses: (state) => state.user?.addresses,

    getUserNotifications: (state) => state.notificationSettings,

    getUserAnnouncements: (state) => state.announcementSettings,

    getUserApiKeys: (state) => state.apiKeys,

    getUserG2faEnabled: (state) => state.user?.info?.security?.g2fa,

    getUserAssets: (state) => state.user?.assets,

    getUserFavorites: (state: User): string[] => state.user?.favorites as string[],

    getUserId: (state: User): string => state?.user?.info?.uid as string,
  },
})
