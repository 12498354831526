<template>
  <NuxtLayout>
    <NuxtPage />
    <overlay-content />
    <p-notify />
    <SignUpModal />
  </NuxtLayout>
</template>

<script setup lang="ts">
smartRouter.init()

const mainStore = useMainStore()

// server side data fetching
useAsyncData('init', async () => await mainStore.init())

// client side data fetching
isUserAuthenticated() && mainStore.userFetch()
</script>
