import lodash from 'lodash'
import type { FormatDate, ObjectRecord } from '@/models'

const $_ = lodash

function getAsset({ asset, type }: { asset: string; type: string }): string {
  return `${process.env.NUXT_API_ASSET_BASE_URL}/${type}/${asset}`
}

function hasKey(obj: ObjectRecord = {}): boolean {
  return Object.keys(obj).length > 0
}

const turkishToEnglish = (text: string): string => {
  if (!text) return ''
  return text
    .replace('Ğ', 'g')
    .replace('Ü', 'u')
    .replace('Ş', 's')
    .replace('I', 'i')
    .replace('İ', 'i')
    .replace('Ö', 'o')
    .replace('Ç', 'c')
    .replace('ğ', 'g')
    .replace('ü', 'u')
    .replace('ş', 's')
    .replace('ı', 'i')
    .replace('ö', 'o')
    .replace('ç', 'c')
}

function deepMerge(target: ObjectRecord, source: ObjectRecord): any {
  for (const key of Object.keys(source)) {
    if (
      source[key] instanceof Object &&
      source[key] !== null &&
      !Array.isArray(source[key]) &&
      !$_.isEmpty(source[key])
    ) {
      target[key] = deepMerge(target[key] || {}, source[key])
    } else if (!$_.isEmpty(source[key])) {
      target[key] = source[key]
    }
  }
  return target
}

const useSticky = (top: number) => {
  const isSticky = ref(false)

  const onScroll = () => {
    isSticky.value = window.scrollY > top
  }

  onMounted(() => {
    if (import.meta.client) {
      window.addEventListener('scroll', onScroll)
    }
  })

  onUnmounted(() => {
    if (import.meta.client) {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return { isSticky }
}

const formatNumber = ({ num = 0, precision = 2, abs = false }: ObjectRecord): number | string => {
  if (!num) return 0
  if (abs) num = Math.abs(num)
  const val = Number(num)
    .toFixed(precision + 2)
    .slice(0, -2)

  return Number(val).toLocaleString('en', {
    minimumFractionDigits: precision,
  })
}

const formatPercent = ({ num = 0, precision = 1 }: ObjectRecord): string | number => {
  const options: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: precision,
  }
  return new Intl.NumberFormat(userLocale(), options).format(Math.abs(Number(num) / 100))
}

const mobileValidator = (text: string) => {
  return text.replace(/\D/g, '').slice(2)
}

const formatDate = ({ date, format = 'date', persistent = false }: FormatDate): string => {
  const currentLang = userLocale()
  const dateFormatter = (f: any) => new Intl.DateTimeFormat(currentLang, f).format(new Date(date))
  const shortDate = (f: any) => new Date(f).toISOString().split('T')[0]
  const dateTimestamp = (f: any) => new Date(f).getTime()

  const formats = {
    date: { year: 'numeric', month: '2-digit', day: '2-digit' },
    hour: { hour: '2-digit', minute: '2-digit', second: '2-digit' },
    hourMinutes: { hour: '2-digit', minute: '2-digit' },
    month: { year: 'numeric', month: 'long', day: 'numeric' },
    monthShort: { month: '2-digit' },
    'DD MMM': { day: '2-digit', month: 'long' },
    'DD MM': { day: '2-digit', month: 'short' },
    year: { year: 'numeric' },
  }

  const now = new Date()
  const inputDate = new Date(date)
  const today = new Date(now)
  today.setHours(0, 0, 0, 0)

  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  const dates = {
    date: dateFormatter(formats.date),
    hour: dateFormatter(formats.hour),
    hourMinutes: dateFormatter(formats.hourMinutes),
    long: `${dateFormatter(formats.date)} ${dateFormatter(formats.hourMinutes)}`,
    month: dateFormatter(formats.month),
    'DD MMM': dateFormatter(formats['DD MMM']),
    'DD MM': dateFormatter(formats['DD MM']),
    longMonth: `${dateFormatter(formats.month)}, ${dateFormatter(formats.hourMinutes)}`,
    shortDate: shortDate(date),
    timestamp: dateTimestamp(date),
  }

  if (persistent) return dates[format]

  if (format === 'long') {
    if (inputDate >= today) {
      return `${$t('general.today')}, ${dates.hourMinutes}`
    } else if (inputDate >= yesterday) {
      return `${$t('general.yesterday')}, ${dates.hourMinutes}`
    }
  }

  return dates[format]
}

const numberWithSymbol = ({
  num,
  precision = 1,
}: {
  num: string | number | any
  precision: number
}): string => {
  if (!num || num === 0) return '0'
  const symbolBox = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' },
    { value: 1, symbol: '' },
    { value: 0.001, symbol: '' },
  ]

  num = num.toString().replace(/[^0-9.]/g, '')
  const index = symbolBox.findIndex(({ value }) => num >= value)
  const value = symbolBox[index]?.value
  const symbol = symbolBox[index]?.symbol

  if (num < 1) return Number(num).toFixed(2)
  return (Number(num) / value).toFixed(precision) + symbol
}

const sanitizePriceInput = (value: string) => value.replace(/[^0-9.,]/g, '')

const maskAsset = (value: string, precision = 4) => {
  const userStore = useUserStore()
  const sign = '＊'
  return userStore.getAssetVisible ? value : sign.repeat(precision)
}

export {
  $_,
  hasKey,
  getAsset,
  deepMerge,
  useSticky,
  formatDate,
  formatNumber,
  formatPercent,
  mobileValidator,
  numberWithSymbol,
  turkishToEnglish,
  sanitizePriceInput,
  maskAsset,
}
