<template>
  <ClientOnly>
    <component :is="isComponent" v-if="isComponent" />
  </ClientOnly>
</template>
<script setup lang="ts">
const dynamicComponents = {
  alarms: defineAsyncComponent(() => import('./Alarms/Alarm.vue')),
  history: defineAsyncComponent(() => import('./OrderDetails/index.vue')),
  orders: defineAsyncComponent(() => import('./OrderDetails/index.vue')),
  deposit: defineAsyncComponent(() => import('./Deposit/index.vue')),
  announcements: defineAsyncComponent(() => import('./Announcements/index.vue')),
  alarmSetter: defineAsyncComponent(() => import('./Alarms/AlarmSetter.vue')),
  alarmList: defineAsyncComponent(() => import('./Alarms/AlarmList.vue')),
  trade: defineAsyncComponent(() => import('./EasyTrade/index.vue')),
}

const route = useRoute()

const isComponent = computed(() => {
  const overlay = route.query.overlay as keyof typeof dynamicComponents
  return dynamicComponents[overlay] || null
})
</script>
