<template>
  <div class="p-snackbar-wrapper">
    <div class="p-snackbar">
      <p-icon v-if="message.severity" size="xmd" v-bind="iconAttributes" />

      <p
        v-if="hasKey(message.title)"
        class="p-snackbar__description"
        v-html="$t(message.title?.langkey, message.title?.params)"
      />

      <p
        v-if="hasKey(message.link)"
        class="p-snackbar__link"
        @click="navigation"
        v-html="$t(message.link?.label?.langkey, message.link?.label?.params)"
      />

      <p-icon size="md" color="succes" icon="close" @on-click="deleteSnackbar" />
    </div>
  </div>
</template>

<script setup lang="ts">
const mainStore = useMainStore()

const props = defineProps({
  message: {
    type: Object,
    default: () => ({}),
  },
})

const iconAttributes = computed(() => {
  const icons = {
    info: { icon: 'info-regular', color: 'base-3' },
    success: { icon: 'circle-check', color: 'success' },
    danger: { icon: 'alert-regular', color: 'danger' },
  } as { [key: string]: { icon: string; color: string } }

  return icons[props.message.severity]
})

const deleteSnackbar = () => {
  mainStore.deleteSnackbar()
}

const navigation = (): void => {
  const { url, target } = props.message.link || {}
  if (url.includes('http')) window.open(url, target)
  // else if (target === '_blank') window.open(this.$router.resolve(url).href, target)
  // else this.$router.push(url)
  deleteSnackbar()
}

onMounted(() => {
  !props.message.persistent && setTimeout(() => deleteSnackbar(), props.message.timeout || 3000)
})
</script>
