// @ts-nocheck
import Pusher, { Channel } from 'pusher-js'
import * as pako from 'pako'
import { ObjectRecord, PusherChannelConfig } from '@/models'
import { useUserStore } from '@/stores/user'
import { useMarketStore } from '@/stores/market'
import { useMainStore } from '@/stores/main'

function decodeMessage(data: string): string {
  const decodedStr = atob(data)
  const charData = decodedStr.split('').map((x) => x.charCodeAt(0))
  return JSON.parse(pako.ungzip(charData, { to: 'string' }))
}

let pusher: Pusher | null = null

if (import.meta.client) {
  pusher = new Pusher(process.env.NUXT_APP_PUSHER_KEY, {
    cluster: 'eu',
    channelAuthorization: {
      customHandler: async (options: ObjectRecord, callback: any) => {
        const { socketId, channelName } = options
        const payload = new URLSearchParams({ socket_id: socketId, channel_name: channelName })
        const userStore = useUserStore()

        await userStore
          .fetchUserPrivateChannels(payload)
          .then((res) => {
            callback(null, res.data)
          })
          .catch((error) => callback(new Error(`HTTP error! status: ${error?.status}`), null))
      },
    } as any,
  })
}

export default {
  subscribe(config: PusherChannelConfig): void {
    if (!pusher) return
    const channel = pusher.subscribe(config.channel)
    this.bindChannel(channel, config)
  },

  unsubscribe(channelName: string): void {
    if (!pusher) return
    pusher.unsubscribe(channelName)
  },

  bindChannel(channel: Channel, config: PusherChannelConfig): void {
    const storesMap = {
      mainStore: useMainStore,
      userStore: useUserStore,
      marketStore: useMarketStore,
    }
    const storeName = config.store || 'mainStore'
    const store = storesMap[storeName]()
    const binder = (data: any) => {
      if (data?.compressed) data.payload = decodeMessage(data.payload)
      store[config?.mutation]({ ...data, ...config.payload })
      if (data?.action === 'force-reload') {
        store[config?.force?.action](config?.force?.payload)
      }
    }
    channel.bind(config.event || 'diff', binder)
  },
}
