<template>
  <p-modal
    width="384px"
    :modal-visibility="visibility"
    @on-close="mainStore.resetDialogAttributes()"
  >
    <div class="p-response-dialog__container gap-24">
      <p-icon
        v-if="mainStore?.getDialogAttributes?.severity === 'success'"
        icon="circle-check"
        color="fg-accent"
        size="xl"
      />
      <p-icon v-else icon="alert-regular" color="fg-danger" size="xl" />

      <p
        class="f-title-20-bold t-fg-primary"
        v-text="$t(`${mainStore?.getDialogAttributes?.title?.langkey}`)"
      />

      <p
        class="f-body-14 t-fg-secondary"
        v-text="$t(`${mainStore?.getDialogAttributes?.description?.langkey}`)"
      />

      <p-button
        v-for="(item, index) in mainStore?.getDialogAttributes?.buttons"
        :key="index"
        button-type="ghost"
        status="success"
        @click="handleButton(item?.action)"
      >
        {{ item?.label?.langkey }}
      </p-button>
    </div>
  </p-modal>
</template>

<script setup lang="ts">
defineProps<{
  visibility: boolean
}>()

const mainStore = useMainStore()

const handleButton = (action: { name: string; target: string; url: string }) => {
  if (action.name === 'close') {
    mainStore.resetDialogAttributes()
  } else if (action.name === 'navigation') {
    mainStore.resetDialogAttributes()
    smartRouter.go(action?.url)
  }
}
</script>
