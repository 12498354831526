import { defineStore } from 'pinia'
import jsonmergepatch from 'json-merge-patch'
import Pusher from '@/plugins/pusher'
import type {
  MainState,
  NotifyResponse,
  CurrencyList,
  MarketConfig,
  MarketList,
  TickerList,
  TickerConfig,
  CurrencyConfig,
  NetworkConfig,
  NetworkList,
  ObjectRecord,
  ConfigState,
} from '~/models'
import locale from '@/locales'
import { notifyBy } from '~/composables/fetch/helper'

export const useMainStore = defineStore('MainStore', {
  // MAIN STATE
  state: (): MainState => {
    return {
      user: {},
      ticker: {},
      routeState: {},
      priceSeries: {},
      activeTour: '',
      snackbarList: [],
      isAppLoaded: false,
      isGlobal: false,
      activeTheme: 'light',
      dialogAttributes: {},
      bannerAttributes: {},
      maintenanceMode: false,
      compIdNotify: false,
      compIdStep: '',
      cdnLocales: {},
      config: {
        networks: {},
        fee_matrix: {},
        markets: {},
        currencies: {},
        app_settings: {},
      },
      announcements: [],
    }
  },

  // MAIN ACTIONS
  actions: {
    setDialogAttributes(dialogAttributes: NotifyResponse) {
      this.dialogAttributes = dialogAttributes
    },

    resetDialogAttributes() {
      this.dialogAttributes = {}
    },

    async init() {
      const cdnBaseUrl = process.env.NUXT_API_ASSET_BASE_URL
      const apiBaseUrl = process.env.NUXT_API_BASE_URL

      await Promise.all([
        $fetch(`${apiBaseUrl}/initials/config`),
        $fetch(`${apiBaseUrl}/initials/ticker/extended`),
        $fetch(`${apiBaseUrl}/initials/price-series`),
        $fetch(`${cdnBaseUrl}/locale/v5/${userLocale()}.json`),
      ]).then((res) => {
        this.config = res[0]?.payload
        this.ticker = res[1]?.payload
        this.priceSeries = res[2]?.payload
        this.cdnLocales = res[3] as Record<string, string>

        this.setLocaleMessages()
      })

      isUserAuthenticated() && this.userFetch()
    },

    userFetch() {
      if (import.meta.client) {
        const authToken = localStorage.getItem('auth-token')
        if (authToken) {
          const userStore = useUserStore()
          userStore.fetchUser()
        }

        this.rootSubscriber()
      }
    },

    setLocaleMessages() {
      locale().setLocaleMessages(this.cdnLocales)
    },

    rootSubscriber() {
      Pusher.subscribe({ channel: 'config', mutation: 'mergeConfigData', store: 'mainStore' })
      Pusher.subscribe({
        channel: 'ticker-extended',
        mutation: 'mergeTickerData',
        store: 'mainStore',
      })
      //  Pusher.subscribe({ channel: 'notification', mutation: 'fireNotify' })
    },

    mergeConfigData(data: any) {
      jsonmergepatch.apply(this.config, data.payload)
    },

    mergeTickerData(data: any) {
      jsonmergepatch.apply(this.ticker, data.payload)
    },

    deleteSnackbar(): void {
      this.snackbarList?.shift()
    },

    fireNotify(payload: ObjectRecord): void {
      notifyBy({ _data: payload?.payload })
    },

    fireSnackbar(payload: ObjectRecord): void {
      this.snackbarList?.push(payload)
    },

    fetchAnnouncements() {
      return $http
        .get(`${process.env.NUXT_API_ASSET_BASE_URL}/content/announcements.json`)
        .then((res: any) => {
          this.announcements = res.payload
        })
    },
  },

  // MAIN GETTERS
  getters: {
    getDialogAttributes: (state: MainState) => state?.dialogAttributes?.message,

    getMarkets: (state: MainState): MarketList => {
      const currencies = Object.keys(state.config?.currencies || {})
      const markets = $_.pickBy(state.config?.markets, (market: MarketConfig) => {
        return (
          !$_.isEmpty(market) &&
          $_.isObject(market) &&
          !market?.suspended &&
          currencies.includes(String(market?.pairs?.market))
        )
      })
      return markets
    },

    getTickers: (state: MainState): TickerList => state.ticker as TickerList,

    getPriceSeries: (state: MainState) => state.priceSeries,

    getAllMarkets: (state: MainState): MarketList => {
      const currencies = Object.keys(state.config?.currencies || {})
      const markets = $_.pickBy(state.config?.markets, (market: MarketConfig) => {
        return (
          !$_.isEmpty(market) &&
          $_.isObject(market) &&
          currencies.includes(String(market?.pairs?.market))
        )
      })
      return markets
    },

    getMarketList: (state: MainState): MarketList => {
      return $_(state.config?.markets)
        .map((market: MarketConfig, id: string) => {
          const ticker = state.ticker?.[id]
          const currency = state.config?.currencies?.[market?.pairs?.market as string]
          return {
            id,
            ...market,
            name: market?.pairs?.market,
            fullName: currency?.name,
            icon: currency?.icon,
            last: Number(ticker?.last) || 0,
            first: Number(ticker?.first) || 0,
            change: Number(ticker?.change) || 0,
            lowest: Number(ticker?.lowest) || 0,
            volume: Number(ticker?.volume) || 0,
            highest: Number(ticker?.highest) || 0,
            percentage: Number(ticker?.percentage) || 0,
            priceSeries: state.priceSeries?.[id],
            searchString: turkishToEnglish(
              `${currency?.name}${currency?.symbol}${id}`.toLowerCase(),
            ),
            volume_total: getVolumeTotal(market, ticker),
            isFavorite: useUserStore().user?.favorites?.includes(id),
          }
        })
        .keyBy('id')
        .value()
    },

    getCurrencies: (state: MainState): CurrencyList => state.config?.currencies as CurrencyList,

    getConfig: (state: MainState) => state.config as ConfigState,

    getBasePrecision:
      (state: MainState) =>
      (currency: string): number =>
        state.config?.currencies?.[currency]?.precision as number,

    getMarketGroups: (state: MainState) => state?.config?.app_settings?.market_groups,

    getNetworks: (state: MainState): NetworkList => state.config?.networks as NetworkList,

    getFreeMatrix: (state: MainState): CurrencyList => state.config?.fee_matrix as FeeMatrix,

    getBankAccounts: (state: MainState) => state?.config?.app_settings?.bank_accounts,

    getCurrency: (state: MainState) => (currency: string) => {
      const currencies = currency.includes('_') ? currency.split('_')[0] : currency
      return (state?.config?.currencies as CurrencyList)[currencies] as CurrencyConfig
    },

    getSnackbarList: (state: MainState) => state.snackbarList,

    getNetwork:
      (state: MainState) =>
      (currency: string): NetworkConfig =>
        (state?.config?.networks as NetworkList)?.[currency] as NetworkConfig,

    getUserTotalBalance(state: MainState): number {
      const userStore = useUserStore()
      const { config, ticker } = state
      const currencies = config?.currencies || {}

      const fiatCurrencies = Object.entries(currencies)
        .filter(([, currency]: [string, CurrencyConfig]) => currency?.labels?.includes('fiat'))
        .map(([key]) => key)

      const totalBalances: number[] = []
      const subzeroBalances: number[] = []

      const userAssets = userStore.user?.assets ?? {}
      const visibleAssets = Object.entries(userAssets).filter(([key]) => !currencies[key]?.hidden)

      visibleAssets.forEach(([key, asset]: [string, any]) => {
        const totalAmount = asset?.total || 0
        const isFiat = fiatCurrencies.includes(key)
        if (isFiat) {
          if (totalAmount > 0) {
            totalBalances.push(Math.abs(totalAmount))
          } else if (totalAmount < 0) {
            subzeroBalances.push(Math.abs(totalAmount))
          }
        } else {
          const priceKey = `${key}_tl`
          const lastPrice = ticker?.[priceKey]?.last || 0

          if (totalAmount > 0) {
            totalBalances.push(mul({ numA: Number(lastPrice), numB: Number(totalAmount) }))
          } else if (totalAmount < 0) {
            subzeroBalances.push(mul({ numA: Number(lastPrice), numB: Math.abs(totalAmount) }))
          }
        }
      })
      const positiveSum = plus({ numbers: totalBalances })
      const negativeSum = plus({ numbers: subzeroBalances })

      return minus({ numA: positiveSum, numB: negativeSum })
    },
    getAnnouncements: (state: MainState) => state.announcements,
  },
})

function getVolumeTotal(market: MarketConfig, ticker: TickerConfig): number {
  let fiatValue = $_.get(ticker, 'last', 0)
  if (market?.pairs?.payment !== getBaseCurrency()) {
    fiatValue = fiatValue * $_.get(ticker, 'last', 0)
  }

  return fiatValue * $_.get(ticker, 'volume', 0)
}
