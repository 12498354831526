<template>
  <transition name="p-snackbar-transition" mode="out-in">
    <template v-if="snackbarList?.length && onNext">
      <p-snackbar v-bind="snackbarList[0]" @on-next="nextSnackbar()" />
    </template>
  </transition>
  <transition name="p-dialog-transition" mode="out-in">
    <template v-if="dialogModal">
      <p-dialog :visibility="dialogModal" />
    </template>
  </transition>
</template>

<script setup lang="ts">
const mainStore = useMainStore()
const snackbarList = computed(() => mainStore.getSnackbarList) || []
const dialogModal = computed<boolean>(() => !!mainStore.dialogAttributes?.message)

const onNext = ref(true)

const nextSnackbar = () => {
  onNext.value = false
  setTimeout(() => (onNext.value = true))
}
</script>
